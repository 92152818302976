import React, { useState, useMemo, useCallback, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"

import { useStorage } from "@app/hooks/useCore"
import { useQueries } from "@app/hooks/useQueries"
import { useShopify } from "@app/hooks/useShopify"
import { useConfigContext } from "@app/providers/config"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useActiveTab } from "@app/hooks/useActiveTab"
import { useCustomerContext } from "@app/providers/customer"
import { useFunctions } from "@app/hooks/useFunctions"
import { NormalisedCart } from "@root/types/cart"
import type { Cart, CartLine } from "@shopify/hydrogen-react/storefront-api-types"

type ContextProps = {
  id: string
  url: string
  count: number
  cart: NormalisedCart | null
  giftWrapActive: boolean
  countryCode: string
  currencyCode: string
  loading: boolean
  setLoading: (loading: boolean) => void
  gotoCheckout: (event?: MouseEvent) => void
  saveCart: (cart: Cart) => void
  createCart: (countryCode: string | undefined, forceNew: boolean) => void
  refreshCartStorage: (cart: NormalisedCart) => void
}

export const CartContext = React.createContext<ContextProps | undefined>(undefined)

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const {
    graphql: {
      mutations: { CART_CREATE, LINES_UPDATE_MUTATION },
      queries: { GET_CART },
    },
  } = useQueries()
  const {
    settings: {
      cart: { expiryTimeInDays },
    },
  } = useConfigContext()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const { store, settings } = useConfigContext()
  const { decorateUrl } = useAnalytics()
  const { cartNormaliser } = useShopify()
  const { refetch: getCartQuery } = useQuery(GET_CART, { fetchPolicy: "no-cache", skip: true })
  const [cartCreate] = useMutation(CART_CREATE)
  const [updateCartMutation] = useMutation(LINES_UPDATE_MUTATION)
  const [cart, setCart] = useState<NormalisedCart | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { customer } = useCustomerContext()
  const { callFunction } = useFunctions()

  const id = useMemo(() => cart?.id || getStorage(settings.keys.cart), [getStorage, settings.keys.cart, cart?.id])

  const url = useMemo(
    () => (cart?.checkoutUrl ? decorateUrl(cart.checkoutUrl.replace(store.shopifyShopDomain, store.shopifyCheckoutUrl)) : ""),
    [cart, store, decorateUrl]
  )

  const countryCode = useMemo(
    () => cart?.buyerIdentity?.countryCode || getStorage(settings.keys.market) || "AU",
    [getStorage, settings.keys.market, cart?.buyerIdentity?.countryCode]
  )

  // const currencyCode = useMemo(() => cart?.cost?.totalAmount?.currencyCode || "AUD", [cart?.cost?.totalAmount?.currencyCode])

  const currencyCode = useMemo(
    () =>
      cart?.cost?.totalAmount?.currencyCode && cart?.cost?.totalAmount?.currencyCode !== "XXX"
        ? cart?.cost?.totalAmount?.currencyCode
        : store.locationCurrencyCode,
    [cart?.cost?.totalAmount?.currencyCode, store.locationCurrencyCode]
  )

  const count = useMemo(
    () =>
      cart?.lines?.reduce((count: number, lineItem: CartLine, i: number) => (i ? count + lineItem.quantity : lineItem.quantity), 0) || 0,
    [cart]
  )

  const giftWrapActive = useMemo(
    () => (cart?.lines?.find(lineItem => lineItem?.attributes?.find(({ key }) => key === settings.cart.giftWrapAttribute)) ? true : false),
    [cart?.lines, settings.cart.giftWrapAttribute]
  )

  useEffect(() => {
    createCart(store.locationRegion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCart = useCallback(async () => {
    try {
      if (id) {
        const countryCode = getStorage(settings.keys.market) || store.locationRegion
        setLoading(true)
        const {
          data: { cart },
        } = await getCartQuery({ countryCode, cartId: id })
        setLoading(false)
        return cart
      }
      return false
    } catch (e) {
      setLoading(false)
      console.error((e as Error).message)
    }
  }, [id, settings.keys.market, store.locationRegion, getCartQuery, getStorage])

  const saveCart = useCallback(
    (cart: Cart) => {
      try {
        const normalisedCart = cartNormaliser(cart)
        const newCart = { ...normalisedCart, lines: normalisedCart.lines }
        setCart(newCart)
        setStorage(settings.keys.market, newCart.buyerIdentity?.countryCode)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [cartNormaliser, setStorage, settings.keys.market]
  )

  const refreshCartStorage = useCallback(
    (cart: NormalisedCart) => {
      setStorage(settings.keys.cart, cart.id, expiryTimeInDays || 0)
    },
    [setStorage, settings.keys.cart, expiryTimeInDays]
  )

  const { visibilityState } = useActiveTab()

  const createCart = useCallback(
    async (countryCode = "AU") => {
      try {
        const existingCart = await getCart()

        if (!existingCart?.id || Object.keys(existingCart).length < 1) {
          const {
            data: {
              cartCreate: { cart },
            },
          } = await cartCreate({
            variables: {
              countryCode,
              input: {
                buyerIdentity: {
                  countryCode,
                },
              },
            },
          })
          if (cart) {
            saveCart(cart)
            refreshCartStorage(cart)
          }
        } else {
          saveCart(existingCart)
        }
      } catch (e) {
        console.error((e as Error).message)
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) removeStorage(settings.keys.cart)
      }
    },
    [getCart, saveCart, cartCreate, removeStorage, refreshCartStorage, settings.keys]
  )

  const updateCart = useCallback(
    async (cartId: string, updatedItems: Array<{ id: string; quantity: number; attributes?: Array<{ key: string; value: string }> }>) => {
      try {
        const existingCart = await getCart()

        if (existingCart?.id) {
          const {
            data: {
              cartLinesUpdate: { cart },
            },
          } = await updateCartMutation({
            variables: {
              cartId,
              lines: updatedItems.map(item => ({
                id: item.id,
                quantity: item.quantity,
                attributes: item.attributes?.map(({ key, value }) => ({ key, value })) || [],
              })),
            },
          })

          if (cart) {
            saveCart(cart)
          } else {
            console.error("Cart update failed. No cart returned.")
          }
        } else {
          console.error("Cart not found")
        }
      } catch (e) {
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) removeStorage(settings.keys.cart)
      }
    },
    [getCart, saveCart, updateCartMutation, removeStorage, settings.keys]
  )

  const gotoCheckout = useCallback(
    async (e?: MouseEvent) => {
      if (e) e.preventDefault()

      if (!cart) return

      const mutatedLines: { id: any; quantity: any; attributes: any }[] = []
      cart?.lines?.forEach(line => {
        const dateValue = line.attributes?.find((attr: { key: string }) => attr.key === "_gift_recipient_delivery_date")?.value
        if (dateValue) {
          const todayDate = new Date()
          const todayDateObject = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate(), 10)
          const dateString = dateValue.split("/")
          const dateAttribute = new Date(parseInt(dateString[2]), parseInt(dateString[0]) - 1, parseInt(dateString[1]), 10)

          if (todayDateObject >= dateAttribute) {
            const formattedDate =
              store.locationRegion === "US"
                ? `${String(todayDate.getMonth() + 1).padStart(2, "0")}/${String(todayDate.getDate()).padStart(
                    2,
                    "0"
                  )}/${todayDate.getFullYear()}`
                : `${String(todayDate.getDate()).padStart(2, "0")}/${String(todayDate.getMonth() + 1).padStart(
                    2,
                    "0"
                  )}/${todayDate.getFullYear()}`
            const mutateLine = {
              ...line,
              attributes: line.attributes.map((attribute: { key: string }) => {
                if (attribute.key === "_gift_recipient_delivery_date") {
                  return { ...attribute, value: formattedDate }
                }
                return attribute
              }),
            }

            mutatedLines.push(mutateLine)
          }
        }
      })

      if (mutatedLines.length > 0) {
        try {
          await updateCart(id, mutatedLines)
        } catch (error) {
          console.error("Error updating cart before checkout", error)
          history.pushState(null, "", window.location.href)
          window.location.replace(cart?.checkoutUrl)
          return
        }
      }

      if (customer?.email) {
        try {
          const response = await callFunction("checkout-multipass", {
            customerEmail: customer.email,
            checkoutId: cart.id,
            checkoutUrl: cart.checkoutUrl,
          })

          const url = response.status !== "error" && response.body.includes("https://") ? response.body : cart.checkoutUrl

          history.pushState(null, "", window.location.href)
          window.location.replace(url)
        } catch (e) {
          history.pushState(null, "", window.location.href)
          window.location.replace(cart.checkoutUrl)
        }
      } else {
        history.pushState(null, "", window.location.href)
        window.location.replace(cart.checkoutUrl)
      }
    },
    [callFunction, cart, customer?.email, id, updateCart]
  )

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      id,
      url,
      count,
      giftWrapActive,
      cart,
      countryCode,
      currencyCode,
      loading,
      setLoading,
      gotoCheckout,
      saveCart,
      createCart,
      refreshCartStorage,
    }),
    [id, url, count, giftWrapActive, cart, countryCode, currencyCode, loading, gotoCheckout, saveCart, createCart, refreshCartStorage]
  )

  useEffect(() => {
    if (cart) createCart(store.locationRegion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityState])

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
}

export const useCartContext = (): ContextProps => ({ ...React.useContext(CartContext) } as ContextProps)
